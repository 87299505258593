import axios from 'axios';


export default {
  loginWithOAuth() {
    window.location.href = '/api/oauth/login';
  },
  async getUser() {
    const res = await axios.get('/api/user');
    return res.data;
  },
  async oAuthLogin(data) {
    const res = await axios.post('/api/oauth/login', data);
    return res.data;
  },
  async oAuthLogout() {
    return axios.post('/api/oauth/logout');
  },
};
