export const initialState = {
  error: false,
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setError(state) {
      state.error = true;
    },
    closeError(state) {
      state.error = false;
    },
  },
};
