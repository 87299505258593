<template>
  <v-app v-if="loaded">
    <Header/>
    <v-content class="full-site-content">
      <div class="main-content" v-if="!loading">
        <router-view/>
      </div>
      <div v-if="loading" class="loader-big">
        <div class="loader-container">
          <div class="loader">Loading...</div>
        </div>
      </div>
    </v-content>
    <Footer/>
    <errorSnack />
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import Header from './components/myheader.vue';
import Footer from './components/footer.vue';
import configMixin from './plugins/configMixin';
import errorMixin from './plugins/errorMixin';
import authModule from './modules/AuthModule';
import store from './store';
import datalayerMixin from './plugins/datalayerMixin';
import errorSnack from './components/dashboard/error-snack.vue';

export default {
  mixins: [configMixin, datalayerMixin, errorMixin],
  store,
  name: 'App',
  components: {
    Header,
    Footer,
    errorSnack,
  },
  data() {
    return {
      loading: false,
    };
  },
  created() {
    this.loadConfig();
    if (this.$route.query && this.$route.query.code) {
      this.handleOAuthRedirect();
    } else {
      this.loadPolSSO();
    }
  },
  methods: {
    pushLoginDatalayer(res) {
      if (_.has(this.$route.query, 'social')) {
        this.$gtm.push({
          event: 'eventTracker',
          eventCat: 'PrimeCsoport',
          eventAct: 'Login',
          eventLbl: this.$route.query.social.charAt(0).toUpperCase() + this.$route.query.social.slice(1),
          eventVal: null,
          eventNIflag: false,
          ...res.user.erica,
        });
      }
    },
    loadPolSSO() {
      if (!Object.keys(this.user).length) { // A Vue a usert egy Observerbe csomagolja
        this.loading = true;
        this.$store.commit('user/setSMSerror', false);
        authModule.getUser().then((res) => {
          // console.log(res);
          this.pushLoginDatalayer(res);
          this.processResponse(res);
        }).catch(() => {
          this.error = this.unexpectedErrorMessage;
          // console.log(err);
          this.pushPageload({});
          this.redirectLogin();
        }).finally(() => {
          this.loading = false;
        });
      } else {
        this.$router.push({
          name: 'dashboard',
        }, () => {});
      }
    },

    redirectLogin() {
      authModule.loginWithOAuth();
    },
    async handleOAuthRedirect() {
      this.loading = true;
      try {
        const data = await authModule.oAuthLogin(this.$route.query);
        this.processResponse(data);
      } catch {
        this.setError();
      } finally {
        this.loading = false;
      }
    },
    processResponse(res) {
      if (res.error) {
        this.error = res.error;
        this.pushPageload({});
        this.redirectLogin();
      } else if (res.smserror) {
        this.$store.commit('user/setSMSerror', true);
        this.pushPageload({});
        this.redirectLogin();
      } else if (res.user) {
        this.handleSuccess(res.user);
      } else {
        this.error = 'polssoerror';
        this.pushPageload({});
        this.redirectLogin();
      }
    },

    handleSuccess(user) {
      this.$store.commit('user/setUser', user);
      this.pushPageload(user.erica);
      this.$router.push({
        name: 'dashboard',
      }, () => {});
    },
  },
  computed: {
    ...mapState('user', ['user']),
    isLogin() {
      return this.$router.currentRoute;
    },
  },
  watch: {
    $route(route) {
      this.loadConfig();
      if (route.name !== 'logout') {
        this.loadPolSSO();
      }
    },
  },
};
</script>

<style lang="css">
 ::-moz-selection { /* Code for Firefox */
  background: #B4FF00 !important;
  color: #002340 !important;
}

::selection {
  background: #B4FF00 !important;
  color: #002340 !important;
}

@import "assets/styles/styles.css";

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.theme--light.v-application {
  background-color: #FFFFFF !important;
}

/*.loader-big {
  border: 8px solid #c4e9ff;
  border-radius: 50%;
  border-top: 8px solid #2791d2;
  width: 200px;
  height: 200px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  display: block;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
}*/

.loader-container {
  margin: auto;
  height: 30px;
}

.loader-big {
  width: 100%;
  min-height: 300px;
  display: flex;
}

.loader, .loader:before, .loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  color: #cdcec9;
  font-size: 10px;
  margin: auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before, .loader:after {
  content: '';
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.main-content {
  width: 100%;
}


</style>
