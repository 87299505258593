import axios from 'axios';
import { mapState } from 'vuex';

const configCall = axios.get('/config.json');
const limitCall = axios.get('/limit.json');
const saltCall = axios.get('/api/config/salt');

export default {
  methods: {
    loadConfig() {
      Promise.all([configCall, limitCall, saltCall]).then((values) => {
        this.$store.commit('config/setData', values[0].data);
        this.$store.commit('config/setLimits', values[1].data);
        this.$store.commit('config/setSalt', values[2].data.salt);
        this.$store.commit('config/setLoaded');
      });
    },
  },
  computed: {
    ...mapState('config', ['config', 'loaded']),
  },
};
