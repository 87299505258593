import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/logout',
      name: 'logout',
      component: () => import('./components/logout.vue'),
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('./components/dashboard.vue'),
    },
  ],
});

export default router;
