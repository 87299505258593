/* eslint-disable no-nested-ternary */
import hash from 'hash.js';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('config', ['salt']),
  },
  methods: {
    hashCtn(ctn) {
      return hash.sha256().update(`${ctn}${ctn.slice(2, 3)}${ctn.slice(4, 5)}${ctn.slice(6, 7)}${this.salt}`).digest('hex');
    },
    getTariffCategory(ctn, isPrepaid) {
      if (isPrepaid) {
        return 'pre voice';
      }
      const ctnData = this.bans[this.selectedBan].ctns.find(x => x.SubscriberNumber === ctn);
      return `${ctnData.PaymentType.toLowerCase()} ${ctnData.ServiceType.toLowerCase()}`;
    },
    getEricaData(ctn1, ctn2, isPrepaid, groupId) {
      if (ctn2) {
        console.log(ctn1);
        console.log(ctn2);
        return {
          creation_ctn_1: this.hashCtn(ctn1),
          creation_ctn_2: this.hashCtn(ctn2),
          group_id: groupId,
          tariffCategory: null,
        };
      }
      return {
        involved_ctn: this.hashCtn(ctn1),
        group_id: groupId,
        tariffCategory: this.getTariffCategory(ctn1, isPrepaid),
      };
    },
    pushCtnsDatalayer() {
      this.$gtm.push({
        event: 'multiSimList',
        ctnListBanId: hash.sha256().update(`${this.ban.BillingAccountId}`).digest('hex'),
        ctnList: [...this.separatedGroups.notInGroup.map(ctn => ({
          tariffId: ctn['price-plan'],
          loyaltyEndDate: ctn['commit-end-date'] || null,
          tariffStatus: this.getDatalayerTariffStatus(ctn),
        })),
        ...this.groups.notInGroup.prePaid.map(card => ({
          tariffId: card.value['price-plan'],
          loyaltyEndDate: null,
          tariffStatus: this.getDatalayerTariffStatus(card.value),
        })),
        ],
      });
    },
    pushPrepaidCtns() {
      this.$gtm.push({
        event: 'multiSimList',
        ctnListBanId: 'view-prepaid-tab',
        ctnList: [...Object.values(this.user.prepaid).filter(ctn => ctn['group-status'] !== 'M').map(ctn => ({
          tariffId: ctn['price-plan'],
          loyaltyEndDate: null,
          tariffStatus: this.getDatalayerTariffStatus(ctn),
        }))],
      });
    },
    pushCtnLevelBans() {
      const ctns = [];
      Object.values(this.user.ctnLvlBans).forEach(ban => ban.ctns.forEach(ctn => ctns.push(ctn)));

      this.$gtm.push({
        event: 'multiSimList',
        ctnListBanId: 'subscriber-level-tab',
        ctnList: [...ctns.filter(ctn => ctn['group-status'] !== 'M').map(ctn => ({
          tariffId: ctn['price-plan'],
          loyaltyEndDate: ctn['commit-end-date'] || null,
          tariffStatus: this.getDatalayerTariffStatus(ctn),
        }))],
      });
    },
    pushAddRemoveCtn(ctn, add, error, eventData) {
      const errorMessage = error && error.response ? error.response.data.message : null;
      this.$gtm.push({
        event: 'eventTracker',
        eventCat: 'PrimeCsoport',
        eventAct: add ? 'Add to group' : 'Delete from group',
        eventLbl: ctn['price-plan'],
        eventVal: null,
        eventNIflag: false,
        errorMessage,
        ...eventData,
      });
    },
    pushCreateGroup(createError, addError, eventData) {
      const createErrorMsg = createError && createError.response ? createError.response.data.message : null;
      const addErrorMsg = addError ? addError.join(';') : null;
      this.$gtm.push({
        event: 'eventTracker',
        eventCat: 'PrimeCsoport',
        eventAct: 'Create',
        eventLbl: createError ? 'failed' : addError ? 'success with blemish' : 'success',
        eventVal: null,
        eventNIflag: false,
        errorMessage: createErrorMsg || addErrorMsg || null,
        ...eventData,
      });
    },
    pushPrepaidInvite(type, error, eventData) {
      const errorMessage = error && error.response ? error.response.data.message : null;
      this.$gtm.push({
        event: 'eventTracker',
        eventCat: 'PrimeCsoport',
        eventAct: 'Prepaid invitation',
        eventLbl: error ? 'error' : type,
        eventVal: null,
        eventNIflag: false,
        errorMessage,
        ...eventData,
      });
    },
    pushPageload(data) {
      const push = {
        originalUrl: window.location.href,
        loginStatus: 'notloggedin',
        accountStatus: null,
        ctnSelection: null,
        customerSegment: null,
        customerSubSegment: null,
        customerType: null,
        customerId: null,
        upgradeAvailable: null,
        earlyHandsetUpgradeFee: null,
        commitmentEndDate: null,
        remainingLoyaltyMonths: null,
        pre2postMigrationAvailable: null,
        ...data,
      };
      this.$gtm.push(push);
    },
    getDatalayerTariffStatus(ctn) {
      const errors = [];
      if (!ctn.isCommitEndDateValid && ctn.isPostpaid) {
        errors.push('not valid - not in loyalty');
      }
      if (!ctn.isActive) {
        errors.push('not valid - inactive ctn');
      }
      if (!ctn.isTariffAddable) {
        errors.push('not valid - not entitled tariff');
      }
      if (this.ban && this.ban['multisim-group-info'] && this.ban['multisim-group-info']['group-type'] !== '411') {
        errors.push('not valid - other group');
      }
      if (errors.length === 0) {
        return 'valid';
      }
      if (errors.length === 1) {
        return errors[0];
      }
      return 'not valid – several issues';
    },
  },
};
