<template>
  <v-snackbar class="technical-error-snack" :value="error" :timeout=0 top>
    <span class="technical-error-text">TECHNIKAI HIBA TÖRTÉNT!</span>
    <v-btn class="technical-error-x" @click="closeError"><img :src="require('../../assets/small-x.svg')" contain height="10px"/></v-btn>
  </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from 'vuex';


export default {
  computed: {
    ...mapState('error', ['error']),
  },
  methods: {
    ...mapMutations('error', ['closeError']),
  },
};
</script>

<style>
.technical-error-snack {
  top: 0px !IMPORTANT;
}

.technical-error-snack .v-snack__wrapper {
  border-radius: 0px 0px 5px 5px;
  background: #FFACAC;
  box-shadow: none !IMPORTANT;
  max-width: 400px;
  height: 40px;
}

.technical-error-text {
  font-size: 1em;
  font-family: yettel_bold,sans-serif,arial;
  color: #002340;
  text-align: center;
  margin: auto;
}

.technical-error-x {
  background-color: rgba(0,0,0,0) !IMPORTANT;
  box-shadow: none;
  margin: 0 !IMPORTANT;
}

.technical-error-x .v-ripple__container {
  display: none;
}

.technical-error-x:active {
  box-shadow: none !IMPORTANT;
}
</style>
