
export default {
  namespaced: true,
  state: { user: {}, smserror: false },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    extendPrepaid(state, payload) {
      state.user.prepaid[payload.ctn] = payload;
    },
    extendPrepaidError(state, payload) {
      state.user.prepaid[payload] = {
        ...state.user.prepaid[payload],
        getCtnError: true,
      };
    },
    extendCtnLvlPostpaids(state, payload) {
      const groupInfo = payload.resp['multisim-group-info'];
      const ban = payload['ban-id'];
      state.user.ctnLvlBans[ban].ctns[payload.index] = {
        ...payload.resp['multisim-member-list'][0],
        disabled: true,
        name: state.user.ctnLvlBans[ban].ctns[payload.index].NickName,
        colorClass: ' red',
        isGray: ' gray',
      };
      // console.log(state.user.ctnLvlBans[ban]);
      if (groupInfo) {
        state.user.ctnLvlBans[ban].ctns[payload.index] = {
          ...state.user.ctnLvlBans[ban].ctns[payload.index],
          groupInfo: {
            banid: groupInfo['ban-id'],
            'group-id': groupInfo['group-id'],
            type: groupInfo['group-type'],
          },
        };
      }
    },
    setSMSerror(state, payload) {
      state.smserror = payload;
    },
  },
};
