import axios from 'axios';
import uuidv1 from 'uuid/v1';

const coridHeaderName = 'Correlation-Id';

const corIdInterceptor = (config) => {
  config.headers = {
    ...config.headers,
    [coridHeaderName]: uuidv1(),
  };
  return config;
};

axios.interceptors.request.use(corIdInterceptor);
