import Vuex from 'vuex';
import config from './config';
import user from './user';
import multisim from './multisim';
import error from './error';

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    config,
    user,
    multisim,
    error,
  },
  strict: debug,
});
