/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import 'babel-polyfill';
import axios from 'axios';
import Vue from 'vue';
import './plugins/vuex-store';
import { init as initApm } from '@elastic/apm-rum';
import VueCookie from 'vue-cookie';
import VueTagManager from 'vue-tag-manager';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import './plugins/axios';


axios.get('/apm.json').then((resp) => {
  initApm(resp.data);
}).catch(() => {
  console.error('error initializing APM RUM agent');
});

Vue.config.productionTip = false;

Vue.use(VueCookie);

Vue.use(VueTagManager, {
  gtmId: 'GTM-G3BC',
  dataLayer: {
    // pageType: 'selfcare',
    // loginStatus: 'notloggedin',
    // accountStatus: null,
    // ctnSelection: null,
    // customerType: null,
    // customerSegment: null,
    // customerSubSegment: null,
    // remainingLoyaltyDays: null,
    // customerID: null,
  },
});

new Vue({
  vuetify,
  router,
  render: h => h(App),
}).$mount('#app');
