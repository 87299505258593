<template>
  <div class="upper-header-container">
    <div class="upper-header">
      <div class="left">
        <a :href="config.urls.yettelhu">
          <img :src="require('../assets/yettel-logo.svg')" contain height="50px"/>
        </a>
        <a :href="config.urls.yettelhu">Vissza a Yettel.hu-ra</a>
        <a :href="user.DisplayName ? config.urls.yettel_signed : config.urls.yettel">Yettel fiók</a>
      </div>
      <div ref="myDropdown" class="right dropdown" v-if="user.DisplayName">
        <a @click="toggleMenu" :class="'profile-container'  + (dropdown ? ' border-display' : '')">
            <img class="profile-container-profpic" :src="user.AvatarURL || require('../assets/dummy.png')" contain height="50px">
            <h3 class="profile-header-title">{{ user.DisplayName }}</h3>
            <div class="profile-dropdown-icon">
              <svg id="arrow" :class="'arrow' +(dropdown ? ' rotate' : '')" viewBox="0 0 500 500">
              <svg viewBox="0 0 480 480">
                <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"/>
              </svg>
              </svg>
            </div>
        </a>
        <button id="myDropdown" :class="'dropdown-content gtmLogoutButton'  + (dropdown ? ' show' : '')" @click="logout()" >
          <div class="exit-content">
            <svg xmlns="http://www.w3.org/2000/svg" class="exit-icon" viewBox="0 0 24 24">
              <path d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16 17L21 12L16 7" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M21 12H9" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="exit-title">Kilépés</div>
          </div>
        </button>
      </div>
      <div v-else-if="$route.name === 'logout'" class="right login-btn">
        <button @click="loginRedirect">Belépés</button>
      </div>

      <div ref="myDropdown2" @click="toggleHam" class="mobile-hamburger">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-bars fa-w-14 fa-3x"><path fill="#002340" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z" class=""></path></svg>
      </div>
      <div id="myDropdown2" :class="'dropdown-content2'  + (dropdown2 ? ' show2' : '')">
        <a class="hamburger-mobile-link" :href="config.urls.yettelhu">Vissza a Yettel.hu-ra</a>
        <a class="hamburger-mobile-link" :href="config.urls.yettel">Yettel fiók</a>
        <a v-if="user.DisplayName" class="hamburger-mobile-link gtmLogoutButton" @click="logout()">Kilépés</a>
        <a v-else-if="$route.name === 'logout'" class="hamburger-mobile-link" @click="loginRedirect">Belépés</a>
      </div>
    </div>

  </div>
</template>

<script>

import { mapState } from 'vuex';
import configMixin from '../plugins/configMixin';
import authModule from '../modules/AuthModule';

export default {
  mixins: [configMixin],
  data() {
    return {
      dropdown: false,
      dropdown2: false,
    };
  },
  created() {
    window.addEventListener('click', this.closeMenu);
    window.addEventListener('click', this.closeHam);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeMenu);
    window.removeEventListener('click', this.closeHam);
  },
  methods: {
    loginRedirect() {
      authModule.loginWithOAuth();
    },
    logout() {
      authModule.oAuthLogout().then(() => {
        this.$store.commit('user/setUser', {});
      }).catch(() => {
      }).finally(() => {
        this.$router.push({ name: 'logout' });
      });
    },
    toggleMenu() {
      this.dropdown = !this.dropdown;
    },
    toggleHam() {
      this.dropdown2 = !this.dropdown2;
    },
    closeMenu(e) {
      if (this.$refs.myDropdown && !this.$refs.myDropdown.contains(e.target)) {
        this.dropdown = false;
      }
    },
    closeHam(e) {
      if (this.$refs.myDropdown2 && !this.$refs.myDropdown2.contains(e.target)) {
        this.dropdown2 = false;
      }
    },
  },
  computed: {
    ...mapState('user', ['user']),
  },
};
</script>

<style>
.upper-header {
  max-width: 1372px;
  height: 95px;
  margin: auto;
}

.mobile-hamburger {
  float: right;
  right: 25px;
  position: absolute;
  top: 28px;
  display: none;
  cursor: pointer;
}

.hamburger-mobile-link {
  display: block;
  width: 250px;
  border-bottom: 1px solid #dadada;
  height: 50px;
  text-align: left;
  text-decoration: none;
  font-family: yettel,sans-serif,arial;
  font-size: 1.2em;
  padding-top: 10px;
  padding-left: 10px;
  color: #002340 !IMPORTANT;
}

.hamburger-mobile-link:hover {
  background-color: #B4FF00;
  color: #002340 !IMPORTANT;
}

.dropdown-content2 {
  background-color: white;
  width: 0px;
  height: auto;
  position: absolute;
  right: 0px;
  top: 95px;
  z-index: 10;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}

.dropdown-content2.show2 {
  width: 250px;
}

.mobile-hamburger svg {
  height: 40px;
}

.upper-header-container {
  background: #B4FF00;
}

.upper-header .left {
  float: left;
  display: flex;
  width: 400px;
  height: 95px;
}

.upper-header .right {
  float: right;
  height: 95px;
  display: flex;
}

.profile-container {
  min-width: 100px;
  height: 75px;
  background-color: #B4FF00;
  border-radius: 12px;
  margin: auto;
  cursor: pointer;
  display: flex;
  text-decoration: none !important;
}

.border-display {
  border-radius: 12px 12px 0px 0px;
  transition: all 0.0s ease-in 0.0s;
}

.profile-container-profpic {
  height: 45px;
  float: left;
  width: 45px;
  border-radius: 50%;
  border: 2px solid #002340;
  margin-top: 15px;
  margin-left: 15px;
  background-color: #002340;
  object-fit: contain;
}

.profile-header-title {
  display: inline-block;
  margin-top: 27px;
  margin-left: 10px;
  margin-right: 10px;
  color: #002340;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1em;
  font-family: yettel,sans-serif,arial;
  
}

.upper-header .left a {
  margin: auto;
  display: block;
  color: #002340;
  font-family: yettel,sans-serif,arial;
  font-size: 0.9375em;
  text-decoration: none;
  align-self: center;
}

.upper-header .left a:nth-child(1) {
  margin-left: 30px;
}

.upper-header .left a:hover {
  text-decoration: underline;
}

.upper-header .left .v-responsive.v-image {
  height: 40px;
  flex: none;
  margin: auto;
  margin-left: 45px;
}

.bottom-header {
  max-width: 1372px;
  height: 100%;
  margin: auto;
  margin-bottom: 50px;
  padding-left: 30px;
  padding-right: 30px;
  overflow: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.ban-container {
  color: #80919F;
  font-size: 1em;
  padding-top: 12px;
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
  height: 64px;
  vertical-align: top;
}

.ban-container:hover {
  cursor: pointer;
  color: #002340;
}

.ban-container .ttitle {
  font-size: 0.8em;
  font-family: yettel_bold,sans-serif,arial;
}

.ban-container.active {
  color: #002340;
  border-bottom: 3px solid #002340;
}

.bottom-header .ban-container p {
  margin: 0;
  text-align: center;
  text-transform: uppercase;
}

.ban-container .bbold {
  font-family: yettel_bold,sans-serif,arial;
  font-size: 1.1em;
}

.dropdown-content {
  right: 0px;
  display: block;
  position: absolute;
  background-color: white;
  /*box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.25);*/
  z-index: 1;
  top: 80px;
  width: 100%;
  font-family: yettel_bold,sans-serif,arial;
  height: 0px;
  border-radius: 0px 0px 12px 12px;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  outline: none;
}

.dropdown-content:hover {
  background-color: #f4f4f4;
}

.profile-container:hover, .profile-container:focus {
  background-color: #B4FF00;
}

.dropdown-content a:hover {
  background-color: #ddd;
  }

.show {
  height: 40px;
}

.rotate {
  transform-origin: center;
  transform: rotate(-180deg);
  fill: #002340;
}

.dropdown {
  position: relative;
  display: inline-block;
  right: 20px;
}

.arrow {
  transition: all 0.3s ease-in-out 0.2s;
}

.profile-dropdown-icon {
  width: 12px;
  display: inline-block;
  height: 12px;
  fill: #002340;
  margin-right: 10px;
  margin-top: 28px;
}

.exit-title {
  margin: auto;
  margin-top: 8px;
}
.exit-icon {
  width: 24px;
  height: 24px;
  fill: none;
  margin: auto;
}

.exit-content {
  height: 40px;
  display:flex;
  margin: auto;
  width: 90px;
}

.login-btn {
  margin-right: 32px;
}

@media (max-width: 850px) {
  .upper-header .left a {
    display: none;
  }

  .upper-header .left a:nth-child(1) {
    display: block;
  }

  .right.dropdown .profile-container .profile-header-title, .right.dropdown .profile-container .profile-dropdown-icon {
    display: none;
  }

  .upper-header .left {
    width: 80px;
  }

  .right.dropdown .profile-container {
    min-width: unset;
    background-color: unset;
  }

  .right.dropdown .dropdown-content {
    right: 54px;
    top: 28px;
    width: 120px;
    border-radius: 12px;
    display: none;
  }

  .upper-header .mobile-hamburger {
    display: block;
  }

  .right.dropdown {
    right: 80px;
  }
  .login-btn {
    display: none !important;
  }
}
</style>
