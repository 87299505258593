import { mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations('error', ['setError']),
    handleError(err) {
      if (err.response.status !== 422) {
        this.setError();
      }
    },
  },
};
