export const initialState = {
  config: {}, loaded: false, limits: {}, memberLimits: {}, salt: '',
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setData(state, payload) {
      state.config = payload;
    },
    setLoaded(state) {
      state.loaded = true;
    },
    setSalt(state, payload) {
      state.salt = payload;
    },
    setLimits(state, payload) {
      state.limits = payload;
    },
    setMemberLimits(state, payload) {
      state.memberLimits = payload;
    },
  },
};
