export const initialState = {
  bans: {}, activeBans: [], inactiveBans: [], selectedBan: {}, inGroupMembers: {},
};
export default {
  namespaced: true,
  state: initialState,
  mutations: {
    updateBan(state, payload) {
      const banid = payload.ban.BillingAccountId;
      state.bans[banid] = payload.resp;
    },
    addBan(state, payload) {
      state.bans = {
        ...state.bans,
        [payload.ban.BillingAccountId]: payload.resp,
      };
      if (payload.ban.isActive) {
        state.activeBans.push(payload.ban);
      } else {
        state.inactiveBans.push(payload.ban);
      }
    },
    setBanList(state, payload) {
      state.banList = [...payload];
      if (payload.length) {
        state.selectedBan = payload[0].BillingAccountId;
      }
    },
    setSelectedBan(state, payload) {
      state.selectedBan = payload;
      if (payload === 'prepaid') {
        state.selectedBan = {
          ban: null,
          prepaid: true,
        };
      }
      if (payload === 'postpaid') {
        state.selectedBan = {
          ban: null,
          postpaid: true,
        };
      }
    },
    setSelectedBanList(state, payload) {
      state.bans[payload.banid] = payload.resp;
    },
    setHiperMemberSize(state, payload) {
      state.inGroupMembers = {
        ...payload.inGroup,
      };
    },
  },
  getters: {
    banList(state) {
      return state.activeBans.concat(state.inactiveBans);
    },
  },
};
